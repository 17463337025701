import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { generateRoute } from '../i18n/helpers'
import Faq from '../components/Faq'

const Pricing = ({ pushUrl }) => {
  const intl = useIntl()

  return (
    <div className="oval-glow">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-10">
            <div className="article">
              <h1 className="article__title center">
                <FormattedMessage id="pricing.title" />
              </h1>
              <div
                className="article__content"
                style={{ marginBottom: 80, textAlign: 'center' }}
              >
                <FormattedMessage id="pricing.description" />
              </div>
            </div>
            <div className="pricing-plans">
              <div className="pricing-plans__table-wrapper">
                <div className="pricing-plans__table-column">
                  <div className="pricing-plans__sub-header pricing-plans__sub-header--free">
                    <FormattedMessage id="pricing.plans.free.name" />
                  </div>
                  <div className="pricing-plans__header pricing-plans__header--free">
                    <FormattedMessage
                      id="pricing.plans.free.subtitle"
                      values={{ n: 3 }}
                    />
                  </div>
                  <div className="pricing-plans__scale-container">
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      2x
                    </div>
                    <div className="pricing-plans__scale">4x</div>
                    <div className="pricing-plans__scale">8x</div>
                  </div>
                  <div className="pricing-plans__list-intro-text">
                    <FormattedMessage id="pricing.plans.free.intro" />
                  </div>
                  <ul className="pricing-plans__list">
                    <li className="pricing-plans__list-item pricing-plans__list-item--alert">
                      <FormattedMessage id="pricing.plans.free.feature_1" />
                    </li>
                    <li className="pricing-plans__list-item pricing-plans__list-item--alert">
                      <FormattedMessage id="pricing.plans.free.feature_2" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.free.feature_3" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.free.feature_4" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.free.feature_5" />
                    </li>
                  </ul>
                  <div className="pricing-plans__price-container">
                    <div
                      className="pricing-plans__button button button--secondary button--medium"
                      onClick={() => {
                        pushUrl(generateRoute(intl.locale, 'routes.signup'))
                      }}
                      role="presentation"
                    >
                      <FormattedMessage id="pricing.plans.free.cta" />
                    </div>
                  </div>
                </div>

                <div className="pricing-plans__table-column pricing-plans__table-column--middle">
                  <div className="pricing-plans__sub-header">
                    <FormattedMessage id="pricing.plans.standard.name" />
                  </div>
                  <div className="pricing-plans__header">
                    <FormattedMessage
                      id="pricing.plans.standard.subtitle"
                      values={{ n: 100 }}
                    />
                  </div>
                  <div className="pricing-plans__scale-container">
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      2x
                    </div>
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      4x
                    </div>
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      8x
                    </div>
                  </div>
                  <div className="pricing-plans__list-intro-text">
                    <FormattedMessage id="pricing.plans.standard.intro" />
                  </div>
                  <ul className="pricing-plans__list">
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_1" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_2" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.live_chat_support" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_2_5" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_3" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_4" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_5" />
                    </li>
                    <li className="pricing-plans__list-item pricing-plans__list-item--social-icons">
                      <div className="icon icon--socials" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.standard.feature_6" />
                    </li>
                  </ul>
                  <div className="pricing-plans__price-container">
                    <div className="pricing-plans__price">
                      $9.9
                    </div>
                    <div className="pricing-plans__vat">
                      <FormattedMessage id="pricing.plans.one_time_cost" />
                    </div>
                    <div className="pricing-plans__vat">
                      <FormattedMessage id="pricing.plans.plus_vat" />
                    </div>
                    <div className="m-b-30 center">
                      <div className="pricing-plans__discount">
                        <span>
                          <FormattedMessage
                            id="pricing.plans.discount_was"
                            values={{ price: '$15' }}
                          />
                        </span>{' '}
                        ‧{' '}
                        <FormattedMessage
                          // To display the dollar amount you can use `pricing.plans.discount_save_dollars` instead
                          id="pricing.plans.discount_save"
                          values={{ percentage: 33 }}
                        />
                      </div>
                    </div>
                    <div
                      className="pricing-plans__button button button--primary button--medium"
                      onClick={() => {
                        pushUrl(
                          generateRoute(intl.locale, 'routes.checkout', {
                            productId: 647401
                          })
                        )
                      }}
                      role="presentation"
                    >
                      <FormattedMessage id="pricing.plans.standard.cta" />
                    </div>
                  </div>
                </div>

                <div className="pricing-plans__table-column">
                  <div className="pricing-plans__table-badge">
                    <FormattedMessage id="pricing.plans.best_deal" />
                  </div>
                  <div className="pricing-plans__sub-header">
                    <FormattedMessage id="pricing.plans.premium.name" />
                  </div>
                  <div className="pricing-plans__header">
                    <FormattedMessage
                      id="pricing.plans.premium.subtitle"
                      values={{ n: 300 }}
                    />
                  </div>
                  <div className="pricing-plans__scale-container">
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      2x
                    </div>
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      4x
                    </div>
                    <div className="pricing-plans__scale pricing-plans__scale--active">
                      8x
                    </div>
                  </div>
                  <div className="pricing-plans__list-intro-text">
                    <FormattedMessage id="pricing.plans.premium.intro" />
                  </div>
                  <ul className="pricing-plans__list">
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_1" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_2" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.live_chat_support" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_2_5" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_3" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_4" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_5" />
                    </li>
                    <li className="pricing-plans__list-item pricing-plans__list-item--social-icons">
                      <div className="icon icon--socials" />
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_6" />{' '}
                    </li>
                    <li className="pricing-plans__list-item">
                      <FormattedMessage id="pricing.plans.premium.feature_7" />
                    </li>
                  </ul>
                  <div className="pricing-plans__price-container">
                    <div className="pricing-plans__price">
                      $19.9
                    </div>
                    <div className="pricing-plans__vat">
                      <FormattedMessage id="pricing.plans.one_time_cost" />
                    </div>
                    <div className="pricing-plans__vat">
                      <FormattedMessage id="pricing.plans.plus_vat" />
                    </div>
                    <div className="m-b-30 center">
                      <div className="pricing-plans__discount">
                        <span>
                          <FormattedMessage
                            id="pricing.plans.discount_was"
                            values={{ price: '$39.9' }}
                          />
                        </span>{' '}
                        ‧{' '}
                        <FormattedMessage
                          id="pricing.plans.discount_save"
                          values={{ percentage: 50 }}
                        />
                      </div>
                    </div>

                    <div
                      className=" pricing-plans__button button button--primary button--medium"
                      onClick={() => {
                        pushUrl(
                          generateRoute(intl.locale, 'routes.checkout', {
                            productId: 647403
                          })
                        )
                      }}
                    >
                      <FormattedMessage id="pricing.plans.premium.cta" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pricing-plans__logos-container">
                <div className="icon icon--payments" />
              </div>

              <h2 className="article__title center">
                <FormattedMessage id="faq.title" />
              </h2>
              <Faq
                items={[
                  {
                    id: 1,
                    question: <FormattedMessage id="faq.question_1" />,
                    answer: <FormattedMessage id="faq.answer_1" />,
                    open: false
                  },
                  {
                    id: 2,
                    question: <FormattedMessage id="faq.question_2" />,
                    answer: <FormattedMessage id="faq.answer_2" />,
                    open: false
                  },
                  {
                    id: 3,
                    question: <FormattedMessage id="faq.question_3" />,
                    answer: <FormattedMessage id="faq.answer_3" />,
                    open: false
                  },
                  {
                    id: 4,
                    question: <FormattedMessage id="faq.question_4" />,
                    answer: <FormattedMessage id="faq.answer_4" />,
                    open: false
                  },
                  {
                    id: 5,
                    question: <FormattedMessage id="faq.question_5" />,
                    answer: <FormattedMessage id="faq.answer_5" />,
                    open: false
                  },
                  {
                    id: 6,
                    question: <FormattedMessage id="faq.question_6" />,
                    answer: <FormattedMessage id="faq.answer_6" />,
                    open: false
                  },
                  {
                    id: 7,
                    question: <FormattedMessage id="faq.question_7" />,
                    answer: <FormattedMessage id="faq.answer_7" />,
                    open: false
                  },
                  {
                    id: 8,
                    question: <FormattedMessage id="faq.question_8" />,
                    answer: <FormattedMessage id="faq.answer_8" />,
                    open: false
                  },
                  {
                    id: 9,
                    question: <FormattedMessage id="faq.question_9" />,
                    answer: <FormattedMessage id="faq.answer_9" />,
                    open: false
                  },
                  {
                    id: 10,
                    question: <FormattedMessage id="faq.question_10" />,
                    answer: <FormattedMessage id="faq.answer_10" />,
                    open: false
                  },
                  {
                    id: 11,
                    question: <FormattedMessage id="faq.question_11" />,
                    answer: <FormattedMessage id="faq.answer_11" />,
                    open: false
                  }
                ]}
              />
              <div className="center">
                <div
                  className="button button--secondary button--large article__contact-us"
                  onClick={() =>
                    pushUrl(generateRoute(intl.locale, 'routes.contact'))
                  }
                  role="presentation"
                >
                  <FormattedMessage id="faq.get_more_help" />
                </div>
              </div>
              <h2 className="article__title center">
                <FormattedMessage id="pricing.volume_discount" />
              </h2>
              <div
                className="article__content"
                style={{ marginBottom: 40, textAlign: 'center' }}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'pricing.having_large_batches'
                  })
                }}
              />
              <div className="center">
                <div
                  className="button button--secondary button--large article__contact-us"
                  onClick={() =>
                    pushUrl(generateRoute(intl.locale, 'routes.contact'))
                  }
                  role="presentation"
                >
                  <FormattedMessage id="pricing.ask_for_high_volume" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default connect(null, { pushUrl: push })(Pricing)
